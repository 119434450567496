<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid" />
          {{ title }}
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow v-show="!formVisibility">
          <CCol md="12">
            <!-- <CInput
              label="Nama Unit Kerja"
              placeholder="Nama Unit Kerja"
              v-model="form.nama_unit_kerja"
            ></CInput> -->
            <CInput
              label="No Usulan"
              placeholder="No Usulan"
              v-model="form.no_surat"
            ></CInput>
          </CCol>
        </CRow>
        <CButtonGroup size="sm">
          <CButton color="info" v-show="!formVisibility" @click="loadData()">
            <CIcon name="cil-search" />Cari
          </CButton>
          <CButton color="warning" v-show="!formVisibility" @click="reset()">
            <CIcon name="cil-loop-circular" />Reset
          </CButton>
          <CButton
            v-show="!formVisibility"
            color="success"
            @click="
              formVisibility = !formVisibility;
              loadData();
            "
          >
            <CIcon :name="icon_button" />
            {{ button_label }}
          </CButton>
        </CButtonGroup>
        <hr />
        <CCollapse :show="formVisibility">
          <form-input @done="resetTabel" @clicked="onClickChild" ref="formInput" />
        </CCollapse>
        <CDataTable
          :items="computedItems"
          :fields="fields"
          hover
          small
          border
          v-show="!formVisibility"
          :loading="loading"
        >
          <template #show_details="{item, index}">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="toggleDetails(item, index)"
                >{{ Boolean(item._toggled) ? "Tutup" : "Verifikasi" }}</CButton
              >
            </td>
          </template>
        </CDataTable>
        <CPagination
          :activePage.sync="page"
          :pages.sync="totalPage"
          size="sm"
          align="end"
          v-show="!formVisibility"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import formInput from "./Form";
const fields = [
  { key: "number", label: "No" },
  { key: "no_surat", label: "No. Usulan" },
  { key: "tgl_surat", label: "Tanggal Usulan" },
  // { key: "nama_unit_kerja", label: "Unit Kerja" },
  { key: "jumlah_usulan", label: "Jumlah yang Diusulkan" },
  { key: "jumlah_verifikasi", label: "Verifikasi" },
  {
    key: "show_details",
    label: "Action",
    sorter: false,
    filter: false,
  },
];
export default {
  name: "VerifikasiMutasiKeluar",
  components: {
    formInput,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
      formVisibility: false,
      loading: false,
      page: 1,
      totalPage: 0,
      optionsInstansi: [],
      optionsUnitKerja: [],
      optionsJabatanUmum: [],
      form: {
        nama_unit_kerja: null,
        no_surat: null,
      },
    };
  },
  watch: {
    page: function() {
      this.loadData();
    },
    formVisibility: function(val) {
      if (!val) {
        this.$refs.formInput.populateInput(null);
      } else {
        this.loadData();
      }
    },
  },
  computed: {
    computedItems() {
      var number = 1;
      let total_pages = 10 * (this.page - 1);
      return this.items.map((item) => {
        var verifikasi = "Menunggu Verifikasi";
        item.pelayanan_detail.map((item) => {
          if (item.status_verifikasi == 1) {
            verifikasi = "Disetujui";
          } else if (item.status_verifikasi == 2) {
            verifikasi = "Berkas Tidak Lengkap";
          } else if (item.status_verifikasi == 3) {
            verifikasi = "Tidak Memenuhi Syarat";
          }
        });
        return {
          ...item,
          number: number++ + total_pages,
          // nama_unit_kerja: item.mst_unit_kerja.nama_unit_kerja,
          jumlah_usulan: item.pelayanan_detail.length,
          jumlah_verifikasi: verifikasi,
        };
      });
    },
    title() {
      return this.formVisibility
        ? "Form Verifikasi Mutasi Keluar"
        : "Tabel Verifikasi Mutasi Keluar";
    },
    icon_button() {
      return !this.formVisibility ? "cil-plus" : "cil-arrow-left";
    },
    button_label() {
      return !this.formVisibility ? "Tambah" : "Kembali";
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    reset() {
      this.form.nama_unit_kerja = null;
      this.form.no_surat = null;
      this.page = 1;
      this.loadData();
    },
    async loadData() {
      try {
        this.loading = true;
        let data = await this.$store.dispatch("verifikasi_mutasi_keluar/index", {
          page: this.page,
          data: this.form,
        });
        this.items = data.data;
        this.totalPage = data.last_page;
      } catch (ex) {
        this.items = [];
      } finally {
        this.loading = false;
      }
    },
    resetTabel() {
      this.$refs.formInput.populateInput(null);
      this.formVisibility = false;
      this.loadData();
    },
    toggleDetails(item) {
      // this.$set(this.items[index], "_toggled", !item._toggled);
      this.formVisibility = true;
      this.$refs.formInput.populateInput(item);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    openNewForm() {
      this.formVisibility = !this.formVisibility;
      this.$refs.formInput.populateInput(null);
    },
    onClickChild(value) {
      this.formVisibility = !this.formVisibility;
      this.$refs.formInput.populateInput(null);
    }
  },
};
</script>
